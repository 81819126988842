import React from 'react';
import AboutBlock from './About';
import ImageGridBlock from './ImageGrid';
import MediaGridBlock from './MediaGrid';

const SectionBlock = ({type, data}) => {
  switch (type) {
    case 'about':
      return <AboutBlock
        paragraphs={data.items} 
        {...data.primary} />
    case 'image_grid':
      return <ImageGridBlock images={data.items}
        {...data.primary} />
    case 'media_library':
      return <MediaGridBlock images={data.items}
        {...data.primary} />
    default:
      return <div></div>
  }
}

const Block = ({
  slice_type,
  ...props
}) => {
  return (
    <SectionBlock type={slice_type}
      data={props} />
  )
}

export default Block;
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import clsx from "clsx";

const AboutBlock = ({
  background_color,
  heading_color,
  subheading_color,
  title,
  subheading,
  paragraphs,
  desktop_section_image,
  section_image__mobile_,
  image_position
}) => {
  let formattedParagraphs = [];
  for(const[k,i] of Object.entries(paragraphs)) {
    console.log(k);
    let string = [];
    if(k < 1) {
      string.push('<p>');
    }
    else {
      string.push(i.add_paragraph_break ? '</p><p>' : '');
    }
    string.push(`<span style="color: ${i.color};">`)
    string.push(i.text.html.replace(/<p>/g, '').replace(/<\/p>/g, ''));
    if((k + 1) === paragraphs.length) {
      string.push('</span></p> ');
    }
    else {
      string.push('</span> ');
    }
    formattedParagraphs.push(string.join(''));
  }
  const textStyle = clsx('text-2xl lg:max-w-[950px] mx-auto', image_position === 'Above text' ? 'order-2 mt-16' : 'order-1 mt-8')
  const imageStyle = clsx(image_position === 'Above text' ? 'order-1 mt-8' : 'order-2 mt-16')
  return (
    <section className="py-24"
      style={{backgroundColor: background_color}}>
      <div className="xl:container mx-auto px-4 text-center grid">
        {title && <h3 className="font-black text-3xl lg:text-4xl mb-2"
          style={{color: heading_color}}>{title.text}</h3>
        }
        {subheading && <p className="font-black text-2xl lg:3xl mb-2"
          style={{color: subheading_color}}>{subheading.text}</p>}
        <div className={textStyle}>
          <div className="grid gap-y-4" dangerouslySetInnerHTML={{__html: formattedParagraphs.join('')}}></div>
        </div>
        <div className={imageStyle}>
          {desktop_section_image &&
            <div className="hidden lg:inline-block">
              <GatsbyImage image={getImage(desktop_section_image.gatsbyImageData)}
                alt={section_image__mobile_.alt || ''} />
            </div>
          }
          {section_image__mobile_ &&
            <div className="inline-block lg:hidden">
              <GatsbyImage image={getImage(section_image__mobile_.gatsbyImageData)}
                alt={section_image__mobile_.alt || ''} />
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default AboutBlock;
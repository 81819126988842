import React from 'react';
import {graphql} from 'gatsby';
import { Seo } from "../../components/Seo";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import Button from '../../components/Button';
import {getButtonColor} from '../../utils/getButtonColor';
import Block from '../../components/Landing/Block';
import {Link} from 'gatsby';


const IndustrialDesignLandingPage = ({data}) => {
  const prismicData = data.prismicIndustrialDesignLp.data;
  
  return (
    <main>
      <section className="py-24"
        style={{backgroundColor: prismicData.background_color}}>
        <div className="xl:container mx-auto px-4 text-center">
          <div className="mx-auto mb-12">
            <Link to="/">
              <GatsbyImage image={getImage(prismicData.sourci_logo.gatsbyImageData)}
                alt={prismicData.sourci_logo.alt || ''}
                className="mb-12" />
            </Link>
            <h1 className="font-black text-4xl lg:text-5xl mb-8">
              {prismicData.section_one_title_parts.map((i,k) => (
                <span className={clsx({
                  'block': i.new_line
                })}
                  style={{color: i.color}}
                  key={k}>{i.part}</span>
              ))}
            </h1>
            <div className="text-xl lg:max-w-[950px] mx-auto"
              style={{color: prismicData.section_one_paragraph_color}}
              dangerouslySetInnerHTML={{__html: prismicData.section_one_paragraph.html}}></div>
          </div>
          <div className="lg:max-w-[970px] rounded-3xl bg-white p-12 mx-auto text-left mb-12">
            <div className="grid lg:grid-cols-3">
              <div>
                <p className="text-sourci-pink font-black text-2xl">Our Industrial Designer specialises in:</p>
              </div>
              <div className="col-span-2 pl-12">
                <ul className="text-base md:text-lg font-bold">
                  {prismicData.section_one_specialise_in.map((i,k) => (
                    <li key={k}
                      className="list-marker before:inline-block before:w-[1em] before:ml-[-1em] before:text-[1.7rem] before:text-sourci-pink">{i.text}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <Button type="external"
            to="https://sourci.leadshook.io/survey/bxwLNll5pe56rhwCG1PBXnqZNyT0krnHTHym1EMs"
            className={clsx('transition-colors font-black text-2xl', getButtonColor(prismicData.section_one_button_color))}
            label={prismicData.section_one_button_text}
            padding="px-10 py-2" />
        </div>
      </section>
      {prismicData.body.map((i,k) => (
        <Block key={k} {...i} />
      ))}
    </main>
  )
}

export const query = graphql`
  query {
    prismicIndustrialDesignLp {
      data {
        background_color
        body {
          ... on PrismicIndustrialDesignLpDataBodyAbout {
            id
            slice_type
            items {
              add_paragraph_break
              color
              text {
                html
              }
            }
            primary {
              background_color
              desktop_section_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              heading_color
              image_position
              section_image__mobile_ {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              subheading {
                text
              }
              title: heading {
                text
              }
              subheading_color
            }
          }
          ... on PrismicIndustrialDesignLpDataBodyImageGrid {
            id
            slice_type
            items {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              paragraph_one {
                html
              }
              paragraph_one_color
              paragraph_two {
                html
              }
              paragraph_two_color
              title {
                text
              }
            }
            primary {
              background_color
              cta_button_style
              cta_button_text
              cta_button_link {
                url
                link_type
              }
              desktop_grid
              title: heading {
                text
              }
              heading_color
              heading_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              mobile_grid
              paragraph {
                html
              }
              subheading {
                text
              }
              subheading_color
            }
          }
          ... on PrismicIndustrialDesignLpDataBodyMediaLibrary {
            id
            slice_type
            items {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background_color1
              desktop_columns
              heading
              heading_color
              mobile_columns
            }
          }
        }
        meta_description
        meta_title
        section_one_button_color
        section_one_button_text
        section_one_paragraph {
          html
        }
        section_one_paragraph_color
        section_one_specialise_in {
          text
        }
        section_one_title_parts {
          color
          new_line
          part
        }
        sourci_logo {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;

export default IndustrialDesignLandingPage;

export function Head({data}) {
  const prismicData = data.prismicIndustrialDesignLp.data;
  return (
    <Seo title={prismicData.meta_title}
      description={prismicData.meta_description}
      canonical="/lp/industrial-design" />
  )
}